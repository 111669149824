import {
  SERVICES_LOADING,
  SERVICES_LOAD_IS_REQUIRED,
  SERVICES_SUCCESS,
  SERVICES_ERROR,
  SERVICES_SET_PARAMS,
  LANGUAGE_CHANGED,
} from '../constants'
import { mergeItems, extractOffset } from '../../utils/reducer_lib'

const initialState = {
  isLoading: false,
  error: null,
  loadIsRequired: true,
  items: [],
  next: ' ',
  params: {
    limit: 6,
    offset: 0,
  },
}

export function servicesList(state = initialState, action) {
  switch (action.type) {
    case SERVICES_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SERVICES_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case SERVICES_SUCCESS:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        items: mergeItems(state.items, action.payload.results),
        next: extractOffset(action.payload.next),
      }
    case SERVICES_SET_PARAMS:
      return {
        ...state,
        params: { ...state.params, ...action.payload },
        loadIsRequired: true,
      }
    case SERVICES_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        error: action.payload,
      }
    case LANGUAGE_CHANGED:
      return {
        ...state,
        loadIsRequired: true,
        items: [],
      }
    default:
      return state
  }
}
