import './loading.scss'
import { ReactComponent as LogoWave } from '../../images/logo_wave.svg'

function Loading() {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center h-100 text-info fs-2">
      <div className="pulsator">
        <LogoWave />
      </div>
    </div>
  )
}

export default Loading
