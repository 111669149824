import {
  VACANCY_PAGE_LOADING,
  VACANCY_PAGE_SUCCESS,
  VACANCY_PAGE_ERROR,
  VACANCY_PAGE_LOAD_IS_REQUIRED,
  LANGUAGE_CHANGED,
} from '../constants'

const initialState = {
  loadIsRequired: true,
  receivedVacancies: {},
}

const receiverState = {
  isLoading: false,
  error: null,
  loadIsRequired: true,
}

function vacancyReceiver(state = receiverState, action) {
  switch (action.type) {
    case VACANCY_PAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case VACANCY_PAGE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loadIsRequired: false,
        isLoading: false,
      }
    case VACANCY_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        ...action.payload,
      }
    default:
      return state
  }
}

export function vacancies(state = initialState, action) {
  switch (action.type) {
    case VACANCY_PAGE_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case VACANCY_PAGE_LOADING:
    case VACANCY_PAGE_SUCCESS:
    case VACANCY_PAGE_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        receivedVacancies: {
          ...state.receivedVacancies,
          [action.slug]: vacancyReceiver(receiverState, action),
        },
      }
    case LANGUAGE_CHANGED:
      return {
        ...state,
        loadIsRequired: true,
        receivedVacancies: {},
      }
    default:
      return state
  }
}
