import routes from './routes'
import { RemoveTrailingSlash } from './removeTrailingslash'
import { useRoutes } from 'react-router-dom'

function AppRouter() {
  const memoRoutes = useRoutes(routes)

  return memoRoutes
}

const Router = () => {
  return (
    <>
      <RemoveTrailingSlash />
      <AppRouter />
    </>
  )
}

export default Router
