import {
  SERVICE_PAGE_LOADING,
  SERVICE_PAGE_SUCCESS,
  SERVICE_PAGE_ERROR,
  SERVICE_PAGE_LOAD_IS_REQUIRED,
  LANGUAGE_CHANGED,
} from '../constants'

const initialState = {
  loadIsRequired: true,
  receivedServices: {},
}

const receiverState = {
  isLoading: false,
  error: null,
  loadIsRequired: true,
}

function serviceReceiver(state = receiverState, action) {
  switch (action.type) {
    case SERVICE_PAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SERVICE_PAGE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loadIsRequired: false,
        isLoading: false,
      }
    case SERVICE_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        ...action.payload,
      }
    default:
      return state
  }
}

export function services(state = initialState, action) {
  switch (action.type) {
    case SERVICE_PAGE_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case SERVICE_PAGE_LOADING:
    case SERVICE_PAGE_SUCCESS:
    case SERVICE_PAGE_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        receivedServices: {
          ...state.receivedServices,
          [action.slug]: serviceReceiver(receiverState, action),
        },
      }
    case LANGUAGE_CHANGED:
      return {
        ...state,
        loadIsRequired: true,
        receivedServices: {},
      }
    default:
      return state
  }
}
