import {
  PROJECTS_LOADING,
  PROJECTS_LOAD_IS_REQUIRED,
  PROJECTS_SUCCESS,
  PROJECTS_ERROR,
  LANGUAGE_CHANGED,
} from '../constants'
import {
  mergeItems,
  extractOffset,
  sortByOrder,
  updateFilters,
  isAllReceived,
} from '../../utils/reducer_lib'
import { PROJECTS_FILTER } from '../../config/filter/params_name'
import { getFilterValue } from '../../utils/location'

export const projectTypes = ['all', 'WEB', 'MOBILE']

function initFilters(items) {
  const filters = {}
  items.forEach((item) => {
    filters[item] = {
      project_type: item,
      next: ' ',
      offset: 0,
    }
  })
  return filters
}

const initialState = {
  isLoading: false,
  error: null,
  loadIsRequired: true,
  items: [],
  filters: initFilters(projectTypes),
  limit: 6,
  current: getFilterValue(PROJECTS_FILTER, false),
  allReceived: false,
}

export function projectsList(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case PROJECTS_LOADING:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: true,
      }
    case PROJECTS_SUCCESS:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        items: sortByOrder(mergeItems(state.items, action.payload.results)),
        filters: updateFilters(
          state.filters,
          extractOffset(action.payload.next),
          getFilterValue(PROJECTS_FILTER),
        ),
        current: getFilterValue(PROJECTS_FILTER, false),
        allReceived: isAllReceived(
          state.filters,
          extractOffset(action.payload.next),
          getFilterValue(PROJECTS_FILTER),
        ),
      }
    case PROJECTS_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        error: action.payload,
      }
    case LANGUAGE_CHANGED:
      return initialState
    default:
      return state
  }
}
