import {
  BLOG_LOADING,
  BLOG_SUCCESS,
  BLOG_ERROR,
  BLOG_LOAD_IS_REQUIRED,
  LANGUAGE_CHANGED,
} from '../constants'

const initialState = {
  loadIsRequired: true,
  receivedBlogs: {},
}

const receiverState = {
  isLoading: true,
  error: null,
  loadIsRequired: true,
}

function blogReceiver(state = receiverState, action) {
  switch (action.type) {
    case BLOG_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case BLOG_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loadIsRequired: false,
        isLoading: false,
      }
    case BLOG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        loadIsRequired: false,
      }
    default:
      return state
  }
}

function updateStore(store, action) {
  return {
    ...store,
    loadIsRequired: false,
    receivedBlogs: {
      ...store.receivedBblogs,
      [action.slug]: blogReceiver(receiverState, action),
    },
  }
}

export function blogs(state = initialState, action) {
  switch (action.type) {
    case BLOG_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case BLOG_LOADING:
    case BLOG_SUCCESS:
    case BLOG_ERROR:
      return updateStore(state, action)
    case LANGUAGE_CHANGED:
      return {
        ...state,
        loadIsRequired: true,
        receivedProjects: {},
      }
    default:
      return state
  }
}
