import { combineReducers } from 'redux'
import { projectsList } from './projects'
import { projects } from './projectPage'
import { servicesList } from './services'
import { mainPage } from './mainPage'
import { services } from './servicesPage'
import { vacanciesList } from './vacanciesList'
import { vacancies } from './vacanciesPage'
import { blogsList } from './blogsList'
import { blogs } from './blogPage'
import { countryDetector } from './country'

import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { browserHistory } from '../../history'

export default combineReducers({
  mainPage,
  servicesList,
  projectsList,
  vacanciesList,
  projects,
  services,
  vacancies,
  blogsList,
  blogs,
  countryDetector,
  router: createRouterReducer(browserHistory),
})
