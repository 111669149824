export function mergeItems(current, updatedTo, key = 'id') {
  const findedUpdatedItem = (item) => updatedTo.find((updItem) => updItem[key] === item[key])
  const filterCurrent = current.filter((item) => item[key] !== findedUpdatedItem(item)?.[key])
  return [...filterCurrent, ...updatedTo]
}

export function sortByOrder(array) {
  if (!array[0]?.order) {
    return array
  }
  return array.sort((a, b) => a.order - b.order)
}

export function extractOffset(url) {
  if (!url) {
    return null
  }
  const receivedUrl = new URL(url)
  const query = new URLSearchParams(receivedUrl.search)
  if (query.has('offset')) {
    return query.get('offset')
  }
  return null
}

export function sortByPublishAt(array) {
  if (!array[0]?.publish_at) {
    return array
  }
  return array.sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at))
}

export function updateFilters(filters, next, filterKey) {
  return {
    ...filters,
    [filterKey]: {
      ...filters[filterKey],
      offset: filters[filterKey].next !== ' ' ? filters[filterKey].next : 0,
      next,
    },
  }
}

export function isAllReceived(filters, next, filterKey) {
  const newFilter = updateFilters(filters, next, filterKey)
  if (newFilter.all.next === null) {
    return true
  }
  const allReceived = Object.values(newFilter)
    .slice(1)
    .every((filter) => filter.next === null)
  return allReceived
}
