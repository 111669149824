import { lazy } from 'react'
import { Trans } from '@lingui/macro'

const MainPage = lazy(() => import('../pages/main'))
const NotFound = lazy(() => import('../pages/notFound'))

const Services = lazy(() => import('../pages/services'))
const ServicesIndex = lazy(() => import('../pages/services/services_index'))
const ServicePage = lazy(() => import('../pages/services/service_page'))

const Projects = lazy(() => import('../pages/projects'))
const ProjectsIndex = lazy(() => import('../pages/projects/projects_index'))
const ProjectPage = lazy(() => import('../pages/projects/project_page'))

const Contacts = lazy(() => import('../pages/contacts'))

const routes = [
  {
    path: '/',
    name: <Trans>Главная</Trans>,
    element: <MainPage />,
  },
  {
    path: '/projects',
    name: <Trans>Проекты</Trans>,
    element: <Projects />,
    children: [
      { index: true, element: <ProjectsIndex /> },
      { path: '/projects/:slug', element: <ProjectPage /> },
    ],
  },
  {
    path: '/services',
    name: <Trans>Услуги</Trans>,
    element: <Services />,
    children: [
      { index: true, element: <ServicesIndex /> },
      { path: '/services/:service', element: <ServicePage />, name: 'service' },
    ],
  },
  {
    path: '/contacts',
    name: <Trans>Контакты</Trans>,
    element: <Contacts />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export default routes
