import { i18n } from '@lingui/core'
import { en, ru } from 'make-plural/plurals'

export const locales = {
  en: 'en',
  ru: 'ru',
}

export const defaultLocale = window.navigator.language || navigator.userLanguage

i18n.loadLocaleData({
  en: { plurals: en },
  ru: { plurals: ru },
})

export async function dynamicActivate(locale) {
  const { messages } = await import(`../locale/${locale}/messages`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}
