import {
  MAIN_PAGE_LOADING,
  MAIN_PAGE_SUCCESS,
  MAIN_PAGE_ERROR,
  MAIN_PAGE_LOAD_IS_REQUIRED,
  LANGUAGE_CHANGED,
} from '../constants'

const initialState = {
  isLoading: false,
  error: null,
  loadIsRequired: true,
  projects: [],
  services: [],
}

export function mainPage(state = initialState, action) {
  switch (action.type) {
    case MAIN_PAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case MAIN_PAGE_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case MAIN_PAGE_SUCCESS:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        projects: action.payload.projects,
        services: action.payload.services,
      }
    case MAIN_PAGE_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        error: action.payload,
      }
    case LANGUAGE_CHANGED:
      return {
        ...state,
        loadIsRequired: true,
        items: [],
      }
    default:
      return state
  }
}
