import {
  BLOGS_LIST_LOADING,
  BLOGS_LIST_LOAD_IS_REQUIRED,
  BLOGS_LIST_SUCCESS,
  BLOGS_LIST_ERROR,
  BLOGS_LIST_SET_PARAMS,
  BLOGS_THEMES_RECEIVED,
  BLOGS_THEMES_ERROR,
  LANGUAGE_CHANGED,
} from '../constants'
import {
  mergeItems,
  extractOffset,
  sortByPublishAt,
  updateFilters,
  isAllReceived,
} from '../../utils/reducer_lib'
import { combineReducers } from 'redux'
import { getFilterValue } from '../../utils/location'
import { BLOG } from '../../config/filter/params_name'

const initialState = {
  isLoading: false,
  error: null,
  loadIsRequired: true,
  items: [],
}

const themesInitialState = {
  loadIsRequired: true,
  themes: [],
  error: null,
}

const filterIntitialState = {
  filter: {
    all: {
      theme_slug: null,
      next: ' ',
      offset: 0,
    },
  },
  allReceived: false,
  current: getFilterValue(BLOG),
  limit: 3,
}

function initFilters(items) {
  const filters = {}
  items.forEach((item) => {
    filters[item.slug] = {
      theme_slug: item.slug,
      next: ' ',
      offset: 0,
    }
  })
  return filters
}

function themes(state = themesInitialState, action) {
  switch (action.type) {
    case BLOGS_THEMES_RECEIVED:
      return {
        ...state,
        loadIsRequired: false,
        themes: action.payload,
      }
    case BLOGS_THEMES_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

function params(state = filterIntitialState, action) {
  switch (action.type) {
    case BLOGS_THEMES_RECEIVED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...initFilters(action.payload),
        },
      }
    case BLOGS_LIST_SUCCESS:
      return {
        ...state,
        filter: updateFilters(
          state.filter,
          extractOffset(action.payload.next),
          getFilterValue(BLOG),
        ),
        allReceived: isAllReceived(
          state.filter,
          extractOffset(action.payload.next),
          getFilterValue(BLOG),
        ),
        current: getFilterValue(BLOG),
      }
    default:
      return state
  }
}

function blogs(state = initialState, action) {
  switch (action.type) {
    case BLOGS_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case BLOGS_LIST_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: action.loadIsRequired,
      }
    case BLOGS_LIST_SUCCESS:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        items: sortByPublishAt(mergeItems(state.items, action.payload.results, 'slug')),
      }
    case BLOGS_LIST_SET_PARAMS:
      return {
        ...state,
        loadIsRequired: action.payload.loadIsRequired,
      }
    case BLOGS_LIST_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        error: action.payload,
      }
    case LANGUAGE_CHANGED:
      return {
        ...state,
        loadIsRequired: true,
        items: [],
      }
    default:
      return state
  }
}

export const blogsList = combineReducers({ blogs, themes, params })
