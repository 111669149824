import { useEffect, useState, useCallback, Suspense } from 'react'
import './scss/style.scss'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { dynamicActivate } from './utils/i18n'
import { subscribe, unsubscribe, getLanguage } from './utils/localStorage'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { languageChangedAction } from './store/actions/common'
import Loading from './components/loading/Loading'
import { Helmet } from 'react-helmet'

import { YMaps } from 'react-yandex-maps'

import { detectCountry } from './store/actions/countryDetector'

import { ReduxRouter } from '@lagunovsky/redux-react-router'
import { browserHistory } from './history'

import Router from './router'

import store from './store/index'

function App() {
  return (
    <Provider store={store}>
      <ReduxRouter store={store} history={browserHistory}>
        <WrappedApp />
      </ReduxRouter>
    </Provider>
  )
}

function WrappedApp() {
  const [locale, setLocale] = useState(getLanguage())
  const dispatch = useDispatch()
  const { isProcessing, isRussia } = useSelector((state) => state.countryDetector)

  const observer = useCallback(
    (lang) => {
      setLocale(lang)
      dispatch(languageChangedAction())
    },
    [dispatch],
  )

  useEffect(() => {
    dynamicActivate(isRussia ? locale : 'en')
  }, [locale, isRussia])

  useEffect(() => {
    subscribe(observer)
    return () => unsubscribe(observer)
  }, [observer])

  useEffect(() => {
    dispatch(detectCountry())
  }, [dispatch])

  return (
    <I18nProvider i18n={i18n}>
      <YMaps query={{ lang: locale }} preload>
        <div className="App App-wrap">
          <Helmet>
            <meta
              name="description"
              content="We are a mobile apps and web development company. We build mobile apps for ios and android and fullstack web apps."
            />
          </Helmet>
          {isRussia && (
            <Helmet>
              <meta name="geo.placename" content="Taganrog" />
              <meta name="geo.region" content="RU-ROS" />
              <meta name="geo.position" content="47.257373159066695;38.91134799847999" />
              <meta name="ICBM" content="47.257373159066695, 38.91134799847999" />
            </Helmet>
          )}
          {isProcessing ? (
            <Loading />
          ) : (
            <Suspense fallback={<Loading />}>
              <Router />
            </Suspense>
          )}
        </div>
      </YMaps>
    </I18nProvider>
  )
}

export default App
