import {
  VACANCIES_LIST_LOADING,
  VACANCIES_LIST_LOAD_IS_REQUIRED,
  VACANCIES_LIST_SUCCESS,
  VACANCIES_LIST_ERROR,
  VACANCIES_LIST_UNLOADED,
  LANGUAGE_CHANGED,
} from '../constants'
import { extractOffset, updateFilters, isAllReceived, mergeItems } from '../../utils/reducer_lib'
import { VACANCIES_FILTER } from '../../config/filter/params_name'
import { getFilterValue } from '../../utils/location'

export const vacanciesStatuses = ['all', 'open', 'archived']

function initFilters(items) {
  const filters = {}
  items.forEach((item) => {
    filters[item] = {
      status: item,
      next: ' ',
      offset: 0,
    }
  })
  return filters
}

const initialState = {
  isLoading: false,
  error: null,
  loadIsRequired: true,
  items: [],
  filters: initFilters(vacanciesStatuses),
  limit: 4,
  current: null,
  allReceived: false,
}

export function vacanciesList(state = initialState, action) {
  switch (action.type) {
    case VACANCIES_LIST_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case VACANCIES_LIST_LOADING:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: true,
      }
    case VACANCIES_LIST_SUCCESS:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        items:
          state.current === getFilterValue(VACANCIES_FILTER, false)
            ? mergeItems(state.items, action.payload.results)
            : action.payload.results,
        filters: updateFilters(
          state.filters,
          extractOffset(action.payload.next),
          getFilterValue(VACANCIES_FILTER),
        ),
        current: getFilterValue(VACANCIES_FILTER, false),
        allReceived: isAllReceived(
          state.filters,
          extractOffset(action.payload.next),
          getFilterValue(VACANCIES_FILTER),
        ),
      }
    case VACANCIES_LIST_ERROR:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
        error: action.payload,
      }
    case VACANCIES_LIST_UNLOADED:
    case LANGUAGE_CHANGED:
      return initialState
    default:
      return state
  }
}
