import { createStore, applyMiddleware, compose } from 'redux'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'
import { browserHistory } from '../history'

const routerMiddleware = createRouterMiddleware(browserHistory)

const store = createStore(rootReducer, compose(applyMiddleware(thunkMiddleware, routerMiddleware)))

export default store
