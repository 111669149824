import axios from 'axios'
import { COUNTRY_SET_PROCESSING, COUNTRY_SET_SUCCESS, COUNTRY_SET_ERROR } from '../constants'

const serviceApiUrl = 'https://get.geojs.io/v1/ip/geo.json'

const detectCountryProcessing = () => ({ type: COUNTRY_SET_PROCESSING })
const detectCountryError = (error) => ({ type: COUNTRY_SET_ERROR, payload: { error } })
const detectCountrySuccess = (payload) => ({ type: COUNTRY_SET_SUCCESS, payload })

export function detectCountry() {
  return async (dispatch) => {
    dispatch(detectCountryProcessing())
    try {
      const response = await axios.get(serviceApiUrl)
      const isRussia = (response.data.country && response.data.country === 'Russia') || false
      dispatch(detectCountrySuccess(isRussia))
    } catch (error) {
      dispatch(detectCountryError(error))
    }
  }
}
