import {
  PROJECT_PAGE_LOADING,
  PROJECT_PAGE_SUCCESS,
  PROJECT_PAGE_ERROR,
  PROJECT_PAGE_LOAD_IS_REQUIRED,
  LANGUAGE_CHANGED,
} from '../constants'

const initialState = {
  loadIsRequired: true,
  receivedProjects: {},
}

const receiverState = {
  isLoading: true,
  error: null,
  loadIsRequired: true,
}

function projectReceiver(state = receiverState, action) {
  switch (action.type) {
    case PROJECT_PAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case PROJECT_PAGE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loadIsRequired: false,
        isLoading: false,
      }
    case PROJECT_PAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        loadIsRequired: false,
      }
    default:
      return state
  }
}

function updateStore(store, action) {
  return {
    ...store,
    loadIsRequired: false,
    receivedProjects: {
      ...store.receivedProjects,
      [action.slug]: projectReceiver(receiverState, action),
    },
  }
}

export function projects(state = initialState, action) {
  switch (action.type) {
    case PROJECT_PAGE_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case PROJECT_PAGE_LOADING:
    case PROJECT_PAGE_SUCCESS:
    case PROJECT_PAGE_ERROR:
      return updateStore(state, action)
    case LANGUAGE_CHANGED:
      return {
        ...state,
        loadIsRequired: true,
        receivedProjects: {},
      }
    default:
      return state
  }
}
