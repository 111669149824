const LANGUAGE = 'language'

let observers = []

function browserLocale() {
  let lang

  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0]
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigator.language
  }

  return lang.split('-')[0]
}

function subscribe(observer) {
  observers.push(observer)
}

function unsubscribe(_observer) {
  observers = observers.filter((observer) => observer !== _observer)
}

function getLanguage() {
  return localStorage.getItem(LANGUAGE) || browserLocale()
}

const notifyLanguageChanged = () => {
  observers.forEach((observer) => {
    observer(getLanguage())
  })
}

function setLanguage(lang) {
  localStorage.setItem(LANGUAGE, lang)
  notifyLanguageChanged()
}

export { setLanguage, subscribe, unsubscribe, getLanguage }
