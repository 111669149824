export const PROJECTS_LOADING = 'PROJECTS_LOADING'
export const PROJECTS_SUCCESS = 'PROJECTS_SUCCESS'
export const PROJECTS_ERROR = 'PROJECTS_ERROR'
export const PROJECTS_LOAD_IS_REQUIRED = 'PROJECTS_LOAD_IS_REQUIRED'

export const PROJECT_PAGE_LOADING = 'PROJECT_PAGE_LOADING'
export const PROJECT_PAGE_SUCCESS = 'PROJECT_PAGE_SUCCESS'
export const PROJECT_PAGE_ERROR = 'PROJECT_PAGE_ERROR'
export const PROJECT_PAGE_LOAD_IS_REQUIRED = 'PROJECTS_PAGE_LOAD_IS_REQUIRED'

export const MAIN_PAGE_LOADING = 'MAIN_PAGE_LOADING'
export const MAIN_PAGE_SUCCESS = 'MAIN_PAGE_SUCCESS'
export const MAIN_PAGE_ERROR = 'MAIN_PAGE_ERROR'
export const MAIN_PAGE_LOAD_IS_REQUIRED = 'MAIN_PAGE_LOAD_IS_REQUIRED'
export const MAIN_PAGE_SET_PARAMS = 'MAIN_PAGE_SET_PARAMS'

export const SERVICES_LOADING = 'SERVICES_LOADING'
export const SERVICES_SUCCESS = 'SERVICES_SUCCESS'
export const SERVICES_ERROR = 'SERVICES_ERROR'
export const SERVICES_LOAD_IS_REQUIRED = 'SERVICES_LOAD_IS_REQUIRED'
export const SERVICES_SET_PARAMS = 'SERVICES_SET_PARAMS'

export const SERVICE_PAGE_LOADING = 'SERVICE_PAGE_LOADING'
export const SERVICE_PAGE_SUCCESS = 'SERVICE_PAGE_SUCCESS'
export const SERVICE_PAGE_ERROR = 'SERVICE_PAGE_ERROR'
export const SERVICE_PAGE_LOAD_IS_REQUIRED = 'SERVICE_PAGE_LOAD_IS_REQUIRED'

export const VACANCY_PAGE_LOADING = 'VACANCY_PAGE_LOADING'
export const VACANCY_PAGE_SUCCESS = 'VACANCY_PAGE_SUCCESS'
export const VACANCY_PAGE_ERROR = 'VACANCY_PAGE_ERROR'
export const VACANCY_PAGE_LOAD_IS_REQUIRED = 'VACANCY_PAGE_LOAD_IS_REQUIRED'

export const VACANCIES_LIST_LOADING = 'VACANCIES_LIST_LOADING'
export const VACANCIES_LIST_SUCCESS = 'VACANCIES_LIST_SUCCESS'
export const VACANCIES_LIST_ERROR = 'VACANCIES_LIST_ERROR'
export const VACANCIES_LIST_LOAD_IS_REQUIRED = 'VACANCIES_LIST_LOAD_IS_REQUIRED'
export const VACANCIES_LIST_UNLOADED = 'VACANCIES_LIST_UNLOADED'

export const BLOGS_LIST_LOADING = 'BLOGS_LIST_LOADING'
export const BLOGS_LIST_SUCCESS = 'BLOGS_LIST_SUCCESS'
export const BLOGS_LIST_ERROR = 'BLOGS_LIST_ERROR'
export const BLOGS_LIST_LOAD_IS_REQUIRED = 'BLOGS_LIST_LOAD_IS_REQUIRED'
export const BLOGS_LIST_SET_PARAMS = 'BLOGS_LIST_SET_PARAMS'
export const BLOGS_THEMES_RECEIVED = 'BLOGS_THEMES_RECEIVED'
export const BLOGS_THEMES_ERROR = 'BLOGS_THEMES_ERROR'

export const BLOG_LOADING = 'BLOG_LOADING'
export const BLOG_SUCCESS = 'BLOG_SUCCESS'
export const BLOG_ERROR = 'BLOG_ERROR'
export const BLOG_LOAD_IS_REQUIRED = 'BLOG_LOAD_IS_REQUIRED'

export const COUNTRY_SET_PROCESSING = 'COUNTRY_SET_PROCESSING'
export const COUNTRY_SET_SUCCESS = 'COUTRY_SET_SUCCESS'
export const COUNTRY_SET_ERROR = 'COUNTRY_SET_ERROR'

export const LANGUAGE_CHANGED = 'LANGUAGE_CAHANGED'
