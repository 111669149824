import { COUNTRY_SET_PROCESSING, COUNTRY_SET_SUCCESS, COUNTRY_SET_ERROR } from '../constants'

const initialState = {
  isProcessing: true,
  isRussia: false,
  error: null,
}

export function countryDetector(state = initialState, action) {
  switch (action.type) {
    case COUNTRY_SET_PROCESSING:
      return { ...state, isProcessing: true }
    case COUNTRY_SET_SUCCESS:
      return { ...state, isProcessing: false, isRussia: action.payload }
    case COUNTRY_SET_ERROR:
      return { ...state, isProcessing: false, error: action.payload.error, isRussia: false }
    default:
      return state
  }
}
